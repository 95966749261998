
















































































































import KTextInput from '@/@core/components/input/KTextInput.vue';
import KHamburgerMenu from '@/@core/components/navigation/KHamburgerMenu.vue';
import KCard from '@/@core/components/other/KCard.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KTableActions from '@/@core/components/table/KTableActions.vue';
import KTableActionButton from '@/@core/components/table/KTableActionButton.vue';
import KTableSearch from '@/@core/components/table/KTableSearch.vue';
import KTableWrapper from '@/@core/components/table/KTableWrapper.vue';
import KText from '@/@core/components/typography/KText.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import {
  defineComponent,
  onMounted,
  Ref,
  ref
} from '@vue/composition-api';
import { useCommodityModel } from './model';
import KDialog from '@/@core/components/dialogs/KDialog.vue';
// import CommodityForm from '../../ui/Commodity-form.vue';
// @ts-ignore
import CommodityForm from '../../ui/commodity-form.vue';

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KText,
    KPageBody,
    KWrapper,
    KCard,
    KHamburgerMenu,
    KTableWrapper,
    KTextInput,
    KTableSearch,
    KTableActionButton,
    KTableActions,
    KDialog,
    CommodityForm
  },
  name: 'CommodityDefaultPage',
  setup() {
    onMounted(async () => {
      await useCommodityModel().onGetManyCommodity();
    });

    return {
      isMobile,
      ...useCommodityModel()
    };
  }
});
