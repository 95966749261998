












import KTextAreaInput from '@/@core/components/input/KTextAreaInput.vue';
import KTextInput from '@/@core/components/input/KTextInput.vue';
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  Ref,
  ref,
  unref,
  watch
} from '@vue/composition-api';
import { required } from '@/@core/helpers/inputValidator';
import useModelValue from '@/@core/helpers/modelValue';

export default defineComponent({
  components: { KTextInput, KTextAreaInput },
  name: 'CommodityForm',
  props: {
    isValid: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object as PropType<any>
    }
  },
  emits: ['valid'],
  setup(props, { emit, attrs }) {
    const modelValue = useModelValue({ emit, attrs });

    const isValidValue = computed({
      get: () => props.isValid,
      set: (v: any) => emit('valid', !v)
    });

    const onSubmit = () => emit('submit', unref(modelValue));

    onMounted(() => {
      if (!modelValue.value) modelValue.value = {};
    });

    return {
      onSubmit,
      modelValue,
      required,
      isValidValue
    };
  }
});
